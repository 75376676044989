var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile" },
    _vm._l(_vm.accountInfoList, function (info) {
      return _c("div", { staticClass: "item-wrapper" }, [
        _c("div", { staticClass: "icon-wrapper" }, [
          info.status === _vm.AuthStatus.AUTHORIZED
            ? _c("img", {
                attrs: { src: require("./img/check_circle_active.png") },
              })
            : _c("img", { attrs: { src: require("./img/check_circle.png") } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "info-wrapper" }, [
          _vm._v(_vm._s(_vm.$t(_vm.StepTitleKeyMap[info.step]))),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }