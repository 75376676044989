/**
 * @author liuzhenkun
 * @date 2018/1/3-上午11:00
 * @file ProfileStepConf
 */

const IDENTITY_LIVING_INFO = 'IDENTITY_LIVING_INFO';
const CONTACT_INFO = 'CONTACT_INFO';
const CASH_LOAN_EMPLOYMENT_INFO = 'CASH_LOAN_EMPLOYMENT_INFO';

export const AuthStatus = {
    AUTHORIZED: 'AUTHORIZED',
    UNAUTHORIZED: 'UNAUTHORIZED'
};

export const StepTitleKeyMap = {
    [IDENTITY_LIVING_INFO]: 'webview.profile.identify',
    [CONTACT_INFO]: 'webview.profile.contact',
    [CASH_LOAN_EMPLOYMENT_INFO]: 'webview.profile.employment'
};
