/**
 * @author liuzhenkun
 * @date 2018/1/3-上午11:00
 * @file profile
 */

import Webview from 'moneycan/common/resource/easycash-indo-webview';

import {AuthStatus, StepTitleKeyMap} from './constant/ProfileStepConf';

export default {
    name: 'Profile',

    title: 'Informasi Pribadi',

    data() {
        return {
            AuthStatus,
            StepTitleKeyMap,
            accountInfoList: null
        };
    },

    mounted() {
        const vm = this;

        Webview.getAccountDetails().then(({body: {body: {required}}}) => {
            vm.accountInfoList = required;
        }).catch(x => x);
    }
};
